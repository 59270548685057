import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Filter from 'components/Filter';
import {Items} from 'components/Items';
import Carousel from 'react-bootstrap/Carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as R from './style';
import logo from '../../assets/shopping_0.png'
import logo1 from '../../assets/shopping_1.png'
import logo2 from '../../assets/shopping_2.png'
import logo3 from '../../assets/shopping_3.png'
import logo4 from '../../assets/shopping_4.png'

import { Button } from 'reactstrap';
import { useItems } from 'App_CTX/context_Item';
import { useBuggy } from 'App_CTX/context_buggy';
import { IItem } from 'interfaces';
function SampleArrow(props:any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gold",color:"black" }}
      onClick={onClick}
    />
  );
}




function Home() {
  var settings = {
    dots: true,
    infinite: true,
    arrows:true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    centerPadding:'10px',
    variableWidth: false,
    prevArrow:<SampleArrow/>,
    nextArrow:<SampleArrow />
  };

  const { items} = useItems();
  const { showBuggy, addItem } = useBuggy();
  const trendManGoods = items?.filter((e)=>e.sex==1).slice(0,7);
  const trendWomanGoods = items?.filter((e)=>e.sex==0).slice(0,8);
  const trendKidsGoods = items?.filter((e)=>e.sex==2).slice(0,6);
  const trendAcceroiesGoods = items?.filter((e)=>e.sex==3).slice(0,6);
  const handleAddProduct  = (e: React.MouseEvent<HTMLButtonElement>,product:IItem) => {
    addItem({ ...product, quantity: 1 });
    showBuggy();
  };
    return(
      <div >
        <div style={{display:'inline-block', paddingLeft:'10vw'}}>
        <R.BrokenGrid>
          <div className='item-11'>
            <h1 className='tittle-1'>Welcome To Corey Wang's Shop</h1>
            <h2 className='tittle-2'>Discover how the future looks in our clothes.</h2>
            <Button className='btn-1'>
            Purchase Now
        </Button>
            </div>
          <div className='item-22' style={{backgroundImage:`url(${logo1})`}}>
                    <div className='hover-layer'>
                    <h1 className='tittle-3'>Woman</h1>
            <h2 className='tittle-4'>Discover how the future looks in our clothes.</h2>
            <NavLink to='/women'>
            <Button className='btn-2'>
            show items
        </Button>
        </NavLink>
                      </div>
          </div>
          <div className='item-22' style={{backgroundImage:`url(${logo2})`}}>
          <div className='hover-layer'>
                    <h1 className='tittle-3'>Man</h1>
            <h2 className='tittle-4'>Discover how the future looks in our clothes.</h2>
            <NavLink to='/men'>
            <Button className='btn-2'>
            show items
        </Button>
        </NavLink>
                      </div>
            </div>
          <div className='item-22' style={{backgroundImage:`url(${logo3})`}}>
          <div className='hover-layer'>
                    <h1 className='tittle-3'>Kids</h1>
            <h2 className='tittle-4'>Discover how the future looks in our clothes.</h2>
            <NavLink to='/kids'>
            <Button className='btn-2'>
            show items
        </Button>
             </NavLink>
                      </div>
            </div>
          <div className='item-22' style={{backgroundImage:`url(${logo4})`}}>
          <div className='hover-layer'>
                    <h1 className='tittle-3'>Accessory</h1>
            <h2 className='tittle-4'>Discover how the future looks in our clothes.</h2>
            <NavLink to='/men'>
            <Button className='btn-2' >
            show items
        </Button>
        </NavLink>
           </div>
            </div>
          </R.BrokenGrid>
          <hr className='style1'></hr>
          </div>
          
        <div  style={{padding:'30px 18% 50px 18%'} } >
        <div style={{padding:'5px 0px 20px 0px'} }>
            <h1>Men's Trend</h1>
            <h2 >Discover how the future looks in our clothes.</h2>
          </div>
        <Slider {...settings}>
                  {/**variableWidth: false，width value doesn't work */}

        {trendManGoods?.map((p,idx) => (
          <div className='slider_2' style={{ width: 300 }}>
        <img className='img_1'  src={require(`assets/items/${p.sku}-1-product.webp`)} alt='logo' />
        <Button className='btn-3' onClick={(e)=>handleAddProduct(e,p)}>
            Add To Buggy
        </Button>
        <h5 style={{textAlign:'center'}}>{p.title}  {p.price}</h5>
        </div>
        ))}
    </Slider>
    <hr className='style1'></hr>
    <div>
    <h1>Women's Trend</h1>
            <h2 >Crafting Your Signature Look.</h2>
          </div>
    <Slider {...settings}>
                  {/**variableWidth: false，width value doesn't work */}

        {trendWomanGoods?.map((p,idx) => (
          <div className='slider_2' style={{ width: 300 }}>
        <img className='img_1'  src={require(`assets/items/${p.sku}-1-product.webp`)} alt='logo' />
        <Button className='btn-3' onClick={(e)=>handleAddProduct(e,p)}>
            Add To Buggy
        </Button>
        <h6 style={{textAlign:'center'}}>{p.title}</h6>
        </div>
        ))}
    </Slider>

    <hr className='style1'></hr>
    <div>
    <h1>Kids' Trend</h1>
            <h2 >Growing Up in Style, One Outfit at a Time.</h2>
          </div>
    <Slider {...settings}>
                  {/**variableWidth: false，width value doesn't work */}

        {trendKidsGoods?.map((p,idx) => (
          <div className='slider_2' style={{ width: 300 }}>
        <img className='img_1'  src={require(`assets/items/${p.sku}-1-product.webp`)} alt='logo' />
        <Button className='btn-3' onClick={(e)=>handleAddProduct(e,p)}>
            Add To Buggy
        </Button>
        <h6 style={{textAlign:'center'}}>{p.title}</h6>
        </div>
        ))}
    </Slider>

    <hr className='style1'></hr>
    <div>
    <h1>Accessories' Trend</h1>
            <h2 >Where Classic Meets Chic.</h2>
          </div>
    <Slider {...settings}>
                  {/**variableWidth: false，width value doesn't work */}

        {trendAcceroiesGoods?.map((p,idx) => (
          <div className='slider_2' style={{ width: 300 }}>
        <img className='img_1'  src={require(`assets/items/${p.sku}-1-product.webp`)} alt='logo' />
        <Button className='btn-3' onClick={(e)=>handleAddProduct(e,p)}>
            Add To Buggy
        </Button>
        <h6 style={{textAlign:'center'}}>{p.title}</h6>
        </div>
        ))}
    </Slider>



    </div>

    
      
     
    
          </div>
    )
}
export default Home;

/**trendAcceroiesGoods
 * <Carousel style={{padding:'200px'}}> 
 * <Carousel.Item>
        <img src={logo} width='1200px' height='300px'/>
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={logo}  className="d-block w-100"  width='1200px' height='300px'/>
        <Carousel.Caption>
          <h3 > Second slide label </h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={logo}  className="d-block w-100"  width='1200px' height='300px'/>
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
       </Carousel>
       style={{padding:'50px 400px 50px 400px'} }

                <img className='item-1 ' src={logo} alt='logo' />
          <img  className='item-2 ' src={logo1} alt='logo' />
          <img  className='item-2 'src={logo2} alt='logo' />
          <img  className='item-2 'src={logo3} alt='logo' />
          <img  className='item-2 ' src={logo4} alt='logo' />

          className='slider-container'


    <Slider {...settings}>
           <div className='slider_2' style={{ width: 300 }}>
        <img className='img_1'  src={slkimg1} alt='logo' />
        <h1>sdsds</h1>
        </div>
        <div className='slider_2' style={{ width: 300 }}>
        <img className='img_1'   src={slkimg1} alt='logo' />
        </div>
        <div className='slider_2' style={{ width: 310 }}>
        <img className='img_1'  src={slkimg1} alt='logo' />
        </div>
        <div className='slider_2' style={{ width: 310 }}>
        <img className='img_1'   src={slkimg1} alt='logo' />
        </div>
        <div className='slider_2' style={{ width: 310 }}>
        <img className='img_1'   src={slkimg1} alt='logo' />
        </div>
        <div className='slider_2' style={{ width: 310 }}>
        <img className='img_1'   src={slkimg1} alt='logo' />
        </div>
      </Slider>

 */
