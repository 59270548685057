import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import React from 'react';
import logo from '../../assets/store_logo_3.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import * as S from './style';
const Footer = ()=>
    {
        return(
            <S.footer >
                 <Container className='footerStyle' fluid='true' >            
                 <Row>
                 <Col lg='3' md='4' sm='6'>
            <div style={{paddingLeft:'100px'}}>
            <S.img2 src={logo}  alt='logo' />
              </div>
          </Col> 
          <Col lg='2' md='4' sm='6'>
            <h5 className='footer__title'>About us</h5>
            <ListGroup className='delivery__time-list'>
              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <NavLink className='inactive_menu' to={'/Home'}>Home</NavLink>               
              </ListGroupItem>

            
              <ListGroupItem className='delivery__time-item border-0 ps-0'>
              <NavLink className='inactive_menu' to={'/Contact'}>Contact</NavLink>
              </ListGroupItem>

              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <span>about</span>
              </ListGroupItem>
            </ListGroup>
          </Col>

          <Col lg='2' md='4' sm='6'>
            <h5 className='footer__title'>Our Shop</h5>
            <ListGroup className='delivery__time-list'>
              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <NavLink className='inactive_menu' to={'/Men'}>Men</NavLink>               
              </ListGroupItem>

            
              <ListGroupItem className='delivery__time-item border-0 ps-0'>
              <NavLink className='inactive_menu' to={'/Women'}>Women</NavLink>
              </ListGroupItem>

              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <NavLink className='inactive_menu' to={'/Kids'}>Kids</NavLink>               
              </ListGroupItem>

            
              <ListGroupItem className='delivery__time-item border-0 ps-0'>
              <NavLink className='inactive_menu' to={'/Accessories'}>Accessories</NavLink>
              </ListGroupItem>

            </ListGroup>
          </Col>

          <Col lg='3' md='4' sm='6'>
            <h5 className='footer__title'>Contact</h5>
            <ListGroup className='delivery__time-list'>
              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <span>Location: 795 Dunbar Lane, OH 44077I</span>
              </ListGroupItem>

              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <span>Hotline: 52984345164</span>
              </ListGroupItem>

              <ListGroupItem className='delivery__time-item border-0 ps-0'>
                <span>Email: clothing_store@gmail.com</span>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col lg='2' md='4' sm='6'>
            <h5 className='footer__title'>Newsletter</h5>
            <p>Subcribe our newsletter</p>
            <div className='newsletter'>
              <input type='email' placeholder='Enter your email'></input>
              <span>
                <i className='ri-send-plane-line'></i>
              </span>
            </div>
        

          </Col>
          
                    </Row>  
                    <Row className='mt-5' style={{textAlign:'center'}}>
                    <hr className='style1'></hr>
            <p className='copyright__text'>
              Copyright - 2024, made by Yi (Corey) Wang  
            </p>
        </Row>   
                 </Container> 
           
              </S.footer>
        )
     }
    export default Footer;