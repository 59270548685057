import { Form ,FormGroup,Label,Input,FormText,Button,FormFeedback, Col,Row,Card,CardBody,CardTitle,CardText,CardImg,CardImgOverlay} from "reactstrap";

import logo from '../../assets/contact_bg2.webp'
import logo2 from '../../assets/location.png'
import logo3 from '../../assets/call.png'
import logo4 from '../../assets/mail.png'
import logo5 from '../../assets/internet.png'
import *  as S from '../../commons/style/theme'
function Contact() {
    return(
        <div>
             <section className='section'>
          <h1 className='tittle-type-1'>Contact</h1>
        </section>
        <div style={{marginTop:'30px',marginLeft:'20%',marginRight:'20%'}}>
      <Row>
        <Col>
        <Card>
        <div style={{textAlign:'center',marginTop:'10px'}}>
        <img
    alt="Card"
    src={logo2}
    style={{width:'60px',height:'60px'}}
  />
  </div>
    <CardBody>
    <CardTitle tag="h5" style={{textAlign:'center'}}>
      Address
    </CardTitle>
    <CardText>
    795 Dunbar Lane, OH 44077
    </CardText>
  </CardBody>
        </Card>

        </Col>
        <Col>
        <Card  >
          <div style={{textAlign:'center',marginTop:'10px'}}>
        <img
    alt="Card"
    src={logo3}
    style={{width:'60px',height:'60px'}}
    
  />
  </div>
  <CardBody>
    <CardTitle tag="h5" style={{textAlign:'center'}}>
      Phone
    </CardTitle>
    <CardText>
          52984345164
    </CardText>
  </CardBody>
        </Card>
        
        </Col>
        <Col>
        <Card>
        <div style={{textAlign:'center',marginTop:'10px'}}>
        <img
    alt="Card"
    src={logo4}
    style={{width:'60px',height:'60px'}}
  />
  </div>
  <CardBody>
    <CardTitle tag="h5" style={{textAlign:'center'}}>
      Mail
    </CardTitle>
    <CardText>
    123789@gmail.com
    </CardText>
  </CardBody>
        </Card>
        
        </Col>
        <Col>
        <Card>
        <div style={{textAlign:'center',marginTop:'10px'}}>
        <img
    alt="Card"
    src={logo5}
    style={{width:'60px',height:'60px'}}
  />
  </div>
  <CardBody>
    <CardTitle tag="h5" style={{textAlign:'center'}}>
      Website
    </CardTitle>
    <CardText>
    https://clothing-store.coreywang.top
    </CardText>
  </CardBody>
        </Card>
        
        </Col>    
      </Row>
      </div>
        <Form style={{marginTop:'30px',marginLeft:'20%',marginRight:'20%'}}>
          
          <Row>
          <Col style={{padding:'10px',borderStyle:'groove',borderColor:`url(${S.theme.colors.third})`}}>
  <Row>
  <Col sm={6} >
      <FormGroup>
   
    <Input
      id="exampleEmail"
      name="email"
      placeholder="First Name"
      type="email"
    />
      </FormGroup>
    </Col>
    <Col sm={6}>
      <FormGroup>
        <Input
          id="exampleEmail"
          name="email"
          placeholder="Last Name"
          type="email"
        />
      </FormGroup>
    </Col>
    <Col md={6}>
      <FormGroup>
      <Input
      id="exampleEmail"
      name="email"
      placeholder="Email"
      type="email"
    />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Input
          id="examplePassword"
          name="password"
          placeholder="Password"
          type="password"
        />
      </FormGroup>
    </Col>
    <FormGroup>
    <Input
      id="exampleText"
      name="text"
      type="textarea"
      placeholder="Text Area"
      rows="8"
    />
  </FormGroup>
  </Row>

  <Row>
    <Col md={6}>
      <FormGroup>
               <Input
          id="exampleCity"
          name="city"
          placeholder="City"
        />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Input
          id="exampleState"
          name="state"
          placeholder="State"
        />
      </FormGroup>
    </Col>
    <Col md={2}>
      <FormGroup>
        <Input
          id="exampleZip"
          name="zip"
           placeholder="Zip"
        />
      </FormGroup>
    </Col>
  </Row>
  <FormGroup check>
    <Input
      id="exampleCheck"
      name="check"
      type="checkbox"
    />
    <Label
      check
      for="exampleCheck"
    >
      Check me out
    </Label>
  </FormGroup>
  <Button style={{textAlign:'center',backgroundColor:`url(${S.theme.colors.fifth})`}}>
    Send Message
  </Button>
  </Col>
  <Col>
  <div>
  <Card inverse>
    <CardImg
      alt="Card image cap"
      src={logo}
      style={{
        height:'480px'
      }}
      width="100%"
    />
    <CardImgOverlay >
      <CardTitle tag="h1" style={{textAlign:'center',marginTop:'15%',fontFamily:'sans-serif'}}>
        Message us
      </CardTitle>
      <CardText style={{marginTop:'15%',fontFamily:'sans-serif'}}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id leo tempor, congue justo at, lobortis orci.
      </CardText>
      <CardText style={{marginTop:'35%',fontFamily:'sans-serif'}}>
        <small className="text-muted" >
          Last updated 3 mins ago
        </small>
      </CardText>
    </CardImgOverlay>
  </Card>
</div>
  </Col>
  </Row>
</Form>
        </div>
    )
}








export default Contact;